//import React from "react";
import './Popular.css';
//import data_product from '../Assets/data';
//import Item from "../item/Item";


const Popular =() => {
    return(
        <div className='temp'>
    
</div>
    )
    
}

export default Popular;

//<div className="popular">
//<h1>Popular Stuff</h1>
//<hr />
//<div className="popular-item">
//    {data_product.map((item,i) =>{
//        return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} description={item.description} />
//    })}
//</div>
//</div>